import React from 'react';
import { Redirect } from 'react-router';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const routes = [
  <Route
    page
    exact
    key={'page-builder'}
    path={'/page-builder'}
    toggleId="pageBuilder"
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="page-builder-demo"
    path="/page-builder/*"
    toggleId="pageBuilder"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="page-builder-plus-demo"
    path="/page-builder-plus/*"
    toggleId="pageBuilder"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/EcommPage'))}
  />,
  <Route
    page
    key="holiday"
    path="/holiday"
    toggleId="holidayLP"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="project-nation"
    path="/artist-series"
    toggleId="projectNationPage"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="motivatedbylove"
    path="/motivatedbylove"
    toggleId="dfbeuros"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Redirect key="lane-break" from="/lanebreak" to="/bike/lanebreak" />,
  <Route
    key="subscribe"
    path="/subscribe"
    toggleId="subscribe"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="setup"
    path="/setup"
    toggleId="setupPage"
    exact
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="row"
    path="/row"
    component={asyncComponent(() => import('./handlers/RowHandler'))}
  />,
  <Route
    key="structured-content-instructors"
    path="/instructors-test"
    toggleId="instructorsPageBuilder"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/EcommPage'))}
  />,
  <Route
    exact
    key="structured-content-financing-citizens-pay"
    path="/financing/citizens-pay"
    toggleId="financingCitizensPageBuilder"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/EcommPage'))}
  />,
  <Route
    exact
    key="structured-content-financing-affirm"
    path="/financing/affirm"
    toggleId="financingAffirmPageBuilder"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/EcommPage'))}
  />,
  <Route
    exact
    key="structured-content-financing"
    path="/financing"
    toggleId="financingPageBuilder"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/EcommPage'))}
  />,
  <Route
    key="peloton-history-report"
    path="/peloton-history-report"
    exact
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
  <Route
    key="klopp"
    path="/klopp"
    exact
    pageBuilderRoute
    toggleId="showDeKloppPage"
    component={asyncComponent(() => import('@page-builder/layout/EcommPage'))}
  />,
  <Route
    key="lavender"
    path="/tread-plus/rear-safety-guard"
    toggleId="lavender"
    pageBuilderRoute
    component={asyncComponent(() => import('@page-builder/layout/EcommPage'))}
  />,
  <Route
    key="nbaallstar2024"
    path="/nbaallstar2024"
    exact
    pageBuilderRoute
    toggleId="nbaAllStarRoute"
    component={asyncComponent(() => import('@page-builder/layout/Page'))}
  />,
];

export default routes;
