import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useIsAffirmZeroPercentPromoActive } from '@ecomm/feature-toggle/AffirmZeroPercentPromo';
import type { AffirmPrequalCallbackHandler } from '@ecomm/financing/affirm/api';
import useCatalogQueryOptions from '@ecomm/graphql/useCatalogQueryOptions';
import type { Money } from '@ecomm/models/Money';
import { isRentalSlug } from '@ecomm/rentals/models';
import { BundleType, toEquipmentTypeFromBundleType } from '@ecomm/shop/models';
import type { Term } from '../models/term';
import { useAffirm, useAffirmPrequalComplete } from './hooks';
import { usePackagesQuery } from './PackageSkusQuery.generated';

// NOTE: BANNER type does not include pricing information in modals
// PRODUCT type includes pricing information in modals
export enum PageType {
  LANDING = 'landing',
  PRODUCT = 'product',
  CART = 'cart',
  BANNER = 'banner',
}

const ZERO_PERCENT_PROMO_ID = 'zero_percent';

export type OwnProps = Omit<React.HTMLAttributes<HTMLAnchorElement>, 'children'> & {
  amount: Money;
  cfuBundleSKU?: string[];
  bundleType: BundleType;
  term: Term;
  pageType?: PageType;
  onPrequalComplete?: AffirmPrequalCallbackHandler;
  wrapper?: keyof JSX.IntrinsicElements;
  children?: (isProcessing: boolean) => React.ReactNode;
};

type DispatchProps = {
  modalOpened?: () => void;
  modalClosed?: () => void;
};

export const LinkView: React.FC<OwnProps & DispatchProps> = ({
  amount,
  cfuBundleSKU,
  bundleType,
  term,
  pageType = PageType.PRODUCT,
  className,
  children,
  modalOpened: opened = () => {},
  modalClosed: closed = () => {},
  onPrequalComplete,
  wrapper: Wrapper = StyledDiv,
  ...props
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  useAffirm();

  useAffirmPrequalComplete(onPrequalComplete);

  useEffect(() => {
    // These functions serve two purposes:
    // 1 - to let children know if the affirm prequal modal is still loading
    // 2 - to make the affirm prequal modal well-behaved
    //     this means that it fires open/close events just like our regular modals
    const modalExists = () =>
      document.getElementsByClassName('affirm-sandbox-container').length !== 0;

    const lookForModalOpened = () => {
      if (modalExists()) {
        clearInterval(processingCheckInterval);
        setIsProcessing(false);
        opened();
      }
    };

    const lookForModalClosed = () => {
      if (!modalExists()) {
        clearInterval(closedCheckInterval);
        closed();
      }
    };

    const processingCheckInterval: ReturnType<typeof setInterval> = setInterval(
      lookForModalOpened,
      1000,
    );
    const closedCheckInterval: ReturnType<typeof setInterval> = setInterval(
      lookForModalClosed,
      1000,
    );

    return () => {
      clearInterval(processingCheckInterval);
      clearInterval(closedCheckInterval);
    };
  }, [isProcessing]);

  const linkProps =
    pageType === PageType.BANNER
      ? {
          className: `affirm-site-modal ${className || ''}`,
          'data-page-type': pageType,
          onClick: () => setIsProcessing(true),
          ...props,
        }
      : {
          className: `affirm-product-modal ${className || ''}`,
          'data-amount': amount,
          'data-page-type': pageType,
          onClick: () => setIsProcessing(true),
          ...props,
        };
  const { data, loading } = usePackagesQuery(
    useCatalogQueryOptions({
      suspend: false,
      variables: { productLine: toEquipmentTypeFromBundleType(bundleType) },
    }),
  );

  const isZeroPercentPromoActive = useIsAffirmZeroPercentPromoActive();

  if (loading) return null;

  if (data?.catalog?.packages) {
    const nonRentalPackages = data.catalog.packages.filter(
      pkg => !isRentalSlug(pkg.slug),
    );

    const skus = Boolean(cfuBundleSKU?.length)
      ? cfuBundleSKU
      : nonRentalPackages[0].connectedFitnessUnit.variants[0].optionSku;
    linkProps['data-sku'] = skus;
  }

  if (bundleType === BundleType.Bike && isZeroPercentPromoActive) {
    linkProps['data-promo-id'] = ZERO_PERCENT_PROMO_ID;
  }

  return (
    <Wrapper role="button" {...linkProps}>
      {(children && children(isProcessing)) || (
        <FormattedMessage id="financing.affirm.apr" />
      )}
    </Wrapper>
  );
};

const StyledDiv = styled.div`` as any;
