import { grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import asyncComponent from '@peloton/code-splitting/asyncComponent';
import type { Locale } from '@peloton/internationalize';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { useHasVariation } from '@peloton/split-testing/hooks';
import { BreakpointWidth } from '@peloton/styles/breakpoints';
import {
  HAMBURGER_NAV_EXPERIMENT,
  HAMBURGER_NAV_VARIATION_ONE,
  HAMBURGER_NAV_VARIATION_TWO,
} from '@acme-ui/nav/constants';
import { useHamburgerNavProductNavs } from '@acme-ui/nav/ProductsMenu/useHamburgerNavProductNavs';
import GeoPickerMenu from '@acme-ui/nav/UtilitiesMenu/GeoPickerMenu';
import useNav from '@content/client/www/nav/useNav';
import AvailableLocales from '@ecomm/internationalize/AvailableLocales';
import useProductNavsMeta from '../hooks/useProductNavsMeta';
import Header from './Header';
import LocaleAccordion from './locale-accordion/LocaleAccordion';
import MenuItemWithAccordion from './MenuItemWithAccordion';
import MenuItemWithFlyout from './MenuItemWithFlyout';
import type { CloseHandlers } from './NavFlyout';
import SecondaryLinks from './SecondaryLinks';

const NavFlyout = asyncComponent(
  () => import(/* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './NavFlyout'),
);

type Props = {
  isOpen: boolean;
  onCloseRequest: () => void;
};

const PanelNav: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseRequest,
}) => {
  const availableProductNavs = useProductNavsMeta();
  const locale = useLocale();
  // useMedia hook uses > instead of >= so we need to subtract 1 from the breakpoint
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge - 1}px)`);

  const isDesktopHamburgerNavVariation1 = useHasVariation(
    HAMBURGER_NAV_EXPERIMENT,
    HAMBURGER_NAV_VARIATION_ONE,
  );

  const isDesktopHamburgerNavVariation2 = useHasVariation(
    HAMBURGER_NAV_EXPERIMENT,
    HAMBURGER_NAV_VARIATION_TWO,
  );

  const isDesktopHamburgerNavActive =
    !isMobileOrTablet &&
    (isDesktopHamburgerNavVariation1 || isDesktopHamburgerNavVariation2);

  const productNavs = useHamburgerNavProductNavs({
    availableProductNavs,
    isDesktopHamburgerNavActive,
  });

  const { content, isLoading } = useNav(locale);
  const geoMenuContent = content?.geoMenu?.['navigation.geoMenu'];

  if (isLoading) {
    return null;
  }

  return (
    <NavFlyout
      isOpen={isOpen}
      ariaLabel={content?.ariaLabel?.value}
      backgroundColor={grey[90]}
      handleRequestClose={onCloseRequest}
    >
      {({ handleRequestClose }: CloseHandlers) => (
        <Container>
          <Header handleClose={handleRequestClose} />
          <Nav>
            <ul>
              {!isDesktopHamburgerNavActive &&
                productNavs.map(productNav => (
                  <MenuItemWithFlyout
                    key={productNav?.cmsId}
                    data={productNav}
                    handleRequestClose={handleRequestClose}
                    className="nav-panel-item"
                  />
                ))}
              {isDesktopHamburgerNavActive &&
                productNavs.map(productNav => (
                  <MenuItemWithAccordion
                    key={productNav?.cmsId}
                    data={productNav}
                    handleRequestClose={handleRequestClose}
                    className="nav-panel-item"
                  />
                ))}
            </ul>
            {!theUserIsInAustria(locale) && !isDesktopHamburgerNavActive && (
              <>
                <Divider aria-hidden="true" className="nav-panel-item" />
                <SecondaryLinks
                  categoryId={content.panelNavLinks?.key}
                  secondaryLinks={content.panelNavLinks}
                  className="nav-panel-item"
                  handleClose={handleRequestClose}
                />
                <AvailableLocales>
                  {(locales: Locale[]) => (
                    <LocaleAccordion availableLocales={locales} darkMode />
                  )}
                </AvailableLocales>
              </>
            )}
            {!theUserIsInAustria(locale) &&
              isDesktopHamburgerNavActive &&
              geoMenuContent && <GeoPickerMenu isWithinHamburgerMenu={true} />}
          </Nav>
        </Container>
      )}
    </NavFlyout>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  padding: 0 1.5rem 3rem;
`;

const Divider = styled.hr`
  background-color: ${grey[60]};
  border: none;
  height: 1px;
  width: 100%;
  margin-top: ${spacing[12]};

  &.nav-panel-item {
    opacity: 0;
  }
`;

export default PanelNav;
