import { CP_MTM_SKU, STUDENT_MTM_SKU } from '@peloton/subscription-plans/skus';

export const CP_TRIAL_PATH = '/digital/offers/cp-free-trial';
export const STUDENT_TRIAL_PATH = '/digital/offers/students-free-trial';

export const SEGMENTS = {
  students: {
    offerUrl: '/offers/students',
    subscriptionSku: STUDENT_MTM_SKU,
  },
  military: {
    offerUrl: '/offers/military',
    subscriptionSku: CP_MTM_SKU,
  },
  healthcare: {
    offerUrl: '/offers/healthcare',
    subscriptionSku: CP_MTM_SKU,
  },
  veterinarian: {
    offerUrl: '/offers/veterinarian',
    subscriptionSku: CP_MTM_SKU,
  },
  educators: {
    offerUrl: '/offers/educators',
    subscriptionSku: CP_MTM_SKU,
  },
  firstResponders: {
    offerUrl: '/offers/firstResponders',
    subscriptionSku: CP_MTM_SKU,
  },
};

export type Segment = {
  offerUrl: string;
  subscriptionSku: string;
};

export type Segments = {
  students: Segment;
  military: Segment;
  healthcare: Segment;
  veterinarian?: Segment;
  educators: Segment;
  firstResponders: Segment;
};

export const getSegments = (): Segments => {
  const segments = {
    ...SEGMENTS,
  };

  return segments;
};
