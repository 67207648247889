import type { Locale } from '@peloton/internationalize';
import { useIsPreviewCopyEnabled } from '@content/client/hooks';
import { fetchWWWBuilderContentAggregate } from '@content/client/lib/fetchBuilderContentAggregate';
import useContentAggregate from '../aggregates/useContentAggregate';
import nav from './nav';

// When preview copy is enabled, we don't pass a custom fetcher so that we can use the default fetcher,
// which goes straight to contentful
// When preview copy is *not* enabled, we pass a custom fetcher that fetches from the builder function
const useCustomFetcher = (locale: Locale) => {
  const preview = useIsPreviewCopyEnabled();

  if (preview) {
    return undefined;
  }

  return () => {
    return fetchWWWBuilderContentAggregate(nav.entryId, locale);
  };
};

const useNav = (locale: Locale): any => {
  const customFetcher = useCustomFetcher(locale);
  return useContentAggregate(nav.entryId, false, locale, customFetcher);
};

export default useNav;
