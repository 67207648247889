import {
  Actions as AnalyticsAction,
  trackCartLoaded,
  trackCartRetrieved,
} from './analytics';
import type {
  RequestAction as TRequestAction,
  RequestInitialSuccess as TRequestInitialSuccess,
  RequestSuccess as TRequestSuccess,
  State as TCartReducerState,
} from './cart';
import {
  Actions as CartActions,
  defaultState as cartDefaultState,
  loadCart,
  loadCartSuccess,
  loadCartInitialSuccess,
  loadCartFailure,
  updateEmailSuccess,
} from './cart';
import type { ReducerState as TCartState } from './combinedReducers';
import cartSummaryReducer from './combinedReducers';
import type {
  ApplyCoupon as TApplyCoupon,
  RemoveCoupon as TRemoveCoupon,
} from './coupon';
import {
  Actions as CouponActions,
  applyCoupon,
  applyCouponSuccess,
  applyCouponFailure,
  removeCoupon,
  removeCouponSuccess,
  removeCouponFailure,
  resetCouponError,
} from './coupon';
import type { State as TPanelState } from './panel';
import { Actions as PanelActions, closeCartPanel, openCartPanel } from './panel';
import type { RemoveItemAction as TRemoveItemAction } from './removeItem';
import { removeItem, ActionType as RemoveItemActionType } from './removeItem';
import {
  getCartEmail,
  getCartItems,
  getCartId,
  getDevicesInCartForProductLine,
  getShippingMethod,
  getShippingQuote,
  getHasDeviceInCart,
  getHasShippingQuote,
  hasSubscriptions,
  getWarrantiesInCartForProductLine,
  selectCart,
  selectCartLoading,
  selectPanelOpen,
  hasBike,
  promoCodeError,
  makeSelectFormattedCartItemNames,
  promoCodeLoading,
  hasTreadPlusInCartStore,
  hasTreadInCartStore,
  hasDeviceOfProductLine,
  isLastCallSuccess,
  getUIState,
  getCartViewedProps,
  getCheckoutCategory,
  getCheckoutTrackProps,
  getCheckoutStartedProps,
  getCartTotal,
  getIsGift,
  getHasTradeIn,
  getSubtotal,
  getProductByProductLine,
  getPromotionId,
  getHasDeviceOfProductLineInCart,
  getHasItemJustBeenAddedToCart,
  getPostalCode,
} from './selectors';

export default cartSummaryReducer;

export {
  AnalyticsAction,
  applyCoupon,
  applyCouponFailure,
  applyCouponSuccess,
  CartActions,
  cartDefaultState,
  closeCartPanel,
  CouponActions,
  getCartEmail,
  getCartId,
  getCartItems,
  getCartTotal,
  getCartViewedProps,
  getCheckoutCategory,
  getCheckoutStartedProps,
  getCheckoutTrackProps,
  getDevicesInCartForProductLine,
  getHasDeviceInCart,
  getHasDeviceOfProductLineInCart,
  getHasItemJustBeenAddedToCart,
  getHasShippingQuote,
  getHasTradeIn,
  getIsGift,
  getPostalCode,
  getProductByProductLine,
  getPromotionId,
  getShippingMethod,
  getShippingQuote,
  getSubtotal,
  getUIState,
  getWarrantiesInCartForProductLine,
  hasBike,
  hasDeviceOfProductLine,
  hasSubscriptions,
  hasTreadInCartStore,
  hasTreadPlusInCartStore,
  isLastCallSuccess,
  loadCart,
  loadCartFailure,
  loadCartInitialSuccess,
  loadCartSuccess,
  makeSelectFormattedCartItemNames,
  openCartPanel,
  PanelActions,
  promoCodeError,
  promoCodeLoading,
  removeCoupon,
  removeCouponFailure,
  removeCouponSuccess,
  removeItem,
  RemoveItemActionType,
  resetCouponError,
  selectCart,
  selectCartLoading,
  selectPanelOpen,
  trackCartLoaded,
  trackCartRetrieved,
  updateEmailSuccess,
};

export type ApplyCoupon = TApplyCoupon;
export type CartReducerState = TCartReducerState;
export type CartState = TCartState;
export type PanelState = TPanelState;
export type RemoveCoupon = TRemoveCoupon;
export type RemoveItemAction = TRemoveItemAction;
export type RequestAction = TRequestAction;
export type RequestInitialSuccess = TRequestInitialSuccess;
export type RequestSuccess = TRequestSuccess;
