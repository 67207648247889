export const HAMBURGER_NAV_EXPERIMENT = 'Desktop Hamburger Nav';
export const HAMBURGER_NAV_VARIATION_ONE = 'Variation #1';
export const HAMBURGER_NAV_VARIATION_TWO = 'Variation #2';

export const BIKES_NAV_KEY = 'nav.bikes';
export const TREADS_NAV_KEY = 'nav.treads';
export const APP_NAV_KEY = 'nav.app';
export const ROW_NAV_KEY = 'nav.row';

//Mobile subnav
export const TREAD_PLUS_NAV_KEY = 'nav.treadPlus';
export const BIKE_PLUS_NAV_KEY = 'nav.bikePlus';
export const BIKE_NAV_KEY = 'nav.bike';
export const TREAD_NAV_KEY = 'nav.tread.productStates';
export const BIKE_PLUS_ROUTE = '/bike-plus';
export const TREAD_PLUS_ROUTE = '/tread-plus';
export const BIKE_ROUTE = '/bike';
export const TREAD_ROUTE = '/tread';

export const BIKES_SECONDARY_LINK_COMPARE_KEY = 'nav.bikes.Compare';
export const TREADS_SECONDARY_LINK_COMPARE_KEY = 'nav.tread.compareTreadmills';

export const CLASSES_PRODUCT_NAV_KEY = 'nav.disciplineClasses';
export const ACCESORIES_PRODUCT_NAV_KEY = 'nav.accessories';

export const ROW_NAV_PRODUCT_CARD_KEY = 'nav.productCard.row';
export const APP_NAV_PRODUCT_CARD_KEY = 'nav.productCard.app';
export const CLASSES_NAV_PRODUCT_CARD_KEY = 'nav.disciplineClasses.productCard';
export const BIKE_PLUS_NAV_PRODUCT_CARD_KEY = 'nav.productCard.bikePlus';
export const BIKE_NAV_PRODUCT_CARD_KEY = 'nav.productCard.bike';
export const TREAD_PLUS_NAV_PRODUCT_CARD_KEY = 'nav.productCard.treadPlus';
export const TREAD_NAV_PRODUCT_CARD_KEY = 'nav.productCard.tread';
export const ACCESSORIES_NAV_PRODUCT_CARD_KEY = 'nav.productCard.accessories';
export const STRENGTH_PLUS_NAV_PRODUCT_CARD_KEY = 'nav.productCard.strengthPlus';
export const GUIDE_NAV_PRODUCT_CARD_KEY = 'nav.productCard.Guide';
export const GIFT_CARD_NAV_PRODUCT_CARD_KEY = 'nav.productCard.GiftCard';
