import {
  Icon,
  grey,
  spacing,
  Flex,
  Eyebrow,
  white,
  Badge,
  brand,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import Accordion from '@ecomm/accordion';
import type { AccordionHeaderStateProps } from '@ecomm/accordion';
import type {
  ProductDetailsCardData,
  ProductDetailsData,
} from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { ProductNavMeta } from '@ecomm/header/models';
import { LinkButton } from '@ecomm/internationalize-ui';
import useFilteredProductCards from '../hooks/useFilteredProductCards';
import { ProductCard } from '../ProductsMenu/HamburgerNav/ProductCard';
import SecondaryLinks from './SecondaryLinks';

type Props = {
  data: ProductNavMeta;
  handleRequestClose: () => void;
  className?: string;
};

type LinkButtonWithBadgeProps = {
  key: string;
  url: string;
  title: string;
  promoBadgeText?: string;
  promoChangeText?: string;
  trackEvent: (event: {
    properties: { category: string; linkName: string; linkTo: string };
  }) => void;
};

const MenuItemWithAccordion: React.FC<React.PropsWithChildren<Props>> = ({
  data: { cmsId },
  handleRequestClose,
  className = '',
}) => {
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);
  const { trackEvent } = useTracking();

  const entry = content?.subnavs.find((subnav: any) => subnav.key === cmsId);

  const filteredProductCards: ProductDetailsData[] = useFilteredProductCards(
    entry?.products || [],
  );
  const showSingleProductCtaSection = filteredProductCards.length === 1;

  const isNavBadgingUpdatesEnabled = useIsToggleActive()('nav_promo_end');

  const { key, title, promoBadgeText, promoChangeText, ...nav } = entry;

  const setPromoBadgeText =
    promoBadgeText && promoBadgeText !== 'hide' ? promoBadgeText : '';

  const setPromoChangeText =
    promoChangeText && promoChangeText !== 'hide' ? promoChangeText : '';

  const displayPromoBadgeText = isNavBadgingUpdatesEnabled
    ? setPromoBadgeText
    : setPromoChangeText;

  const hasSecondaryLinks = !!entry?.secondaryLinks;
  const url = nav.products?.[0]?.primaryCta.url;

  if (isLoading) {
    return null;
  }

  if (!entry) {
    return null;
  }

  if ((cmsId as string) === 'nav.apparel' || theUserIsInAustria(locale)) {
    return (
      <>
        <MenuLinkItem className={className}>
          <ButtonChevronContainer data-test-id={key}>
            <LinkButtonWithBadge
              key={key}
              url={url}
              title={title}
              promoBadgeText={displayPromoBadgeText}
              trackEvent={trackEvent}
            />
          </ButtonChevronContainer>
        </MenuLinkItem>
        <Divider />
      </>
    );
  }

  if ((cmsId as string) === 'nav.deals') {
    return (
      <>
        <MenuLinkItem className={className}>
          <HighlightedButtonContainer alignItems="center" data-test-id={key}>
            <LinkButtonWithBadge
              key={key}
              url={url}
              title={title}
              promoBadgeText={displayPromoBadgeText}
              trackEvent={trackEvent}
            />
          </HighlightedButtonContainer>
        </MenuLinkItem>
        <Divider />
      </>
    );
  }

  return (
    <li className={className} data-test-id={key}>
      <StyledAccordion
        name={title}
        renderHeader={MenuItemAccordionHeader(
          title,
          () => {
            trackEvent({
              properties: {
                category: key,
                linkName: title,
                parent: 'DesktopHamburgerNav',
              },
            });
          },
          displayPromoBadgeText ?? '',
        )}
      >
        <StyledFlex
          flexDirection="row"
          flexWrap={(cmsId as string) === 'nav.accessories' ? 'wrap' : 'nowrap'}
          gap={spacing[24]}
          hasSecondaryLinks={hasSecondaryLinks}
        >
          {filteredProductCards.map((productData: ProductDetailsCardData) => (
            <ProductCard
              productCard={productData}
              showSingleProductCtaSection={showSingleProductCtaSection}
              key={productData?.key}
            />
          ))}
        </StyledFlex>
        {hasSecondaryLinks && (
          <SecondaryLinks
            categoryId={cmsId}
            secondaryLinks={entry.secondaryLinks}
            handleClose={handleRequestClose}
          />
        )}
      </StyledAccordion>
      <Divider />
    </li>
  );
};

const MenuItemAccordionHeader = (
  title: string,
  trackClick: () => void,
  promoBadgeText?: string,
  // eslint-disable-next-line react/display-name
) => ({ isOpen }: AccordionHeaderStateProps) => (
  // eslint-disable-next-line styled-components-a11y/click-events-have-key-events
  <ButtonChevronContainer role="button" tabIndex={0} onClick={trackClick}>
    <StyledEyebrow size="large" textColor={white}>
      {title}
      {promoBadgeText && (
        <StyledBadge variant="horizontal" theme="accent">
          {promoBadgeText}
        </StyledBadge>
      )}
    </StyledEyebrow>

    <StyledChevron
      name="chevron"
      primaryColor={grey[50]}
      aria-hidden={true}
      rotate={isOpen ? 90 : 270}
      height={12}
    />
  </ButtonChevronContainer>
);

export default MenuItemWithAccordion;

const LinkButtonWithBadge: React.FC<LinkButtonWithBadgeProps> = ({
  key,
  url,
  title,
  promoBadgeText,
  trackEvent,
}) => {
  return (
    <BadgeContainer>
      <StyledLinkButton
        href={url}
        text={title}
        color="dark"
        style={{ margin: '0', padding: `${spacing[32]} 0` }}
        onClick={() => {
          trackEvent({
            properties: {
              category: key,
              linkName: title,
              linkTo: url,
            },
          });
        }}
      />
      {promoBadgeText && (
        <StyledBadge variant="horizontal" theme="accent">
          {promoBadgeText}
        </StyledBadge>
      )}
    </BadgeContainer>
  );
};

const StyledAccordion = styled(Accordion)<{ name: string }>`
  ${({ name }) => `
    > #${name}AccordionControl {
      padding: ${spacing[32]} 0; !important;
      margin: unset; !important;

      &:hover h2 {
        color: ${grey[50]} !important;
      }
    }`};
`;

const MenuLinkItem = styled.li`
  &:hover a {
    color: ${grey[50]} !important;
  }
`;

const StyledChevron = styled(Icon)`
  margin-right: ${spacing[8]};
  transition: all 0.4s ease-in-out 0s;
`;

const ButtonChevronContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: ${grey[50]} !important;
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const StyledEyebrow = styled(Eyebrow)`
  &:hover {
    color: ${grey[50]} !important;
  }
  ${ButtonChevronContainer}:hover & {
    color: ${grey[50]} !important;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  width: 100% !important;

  &:hover {
    background-color: ${grey[90]} !important;
    border-color: transparent !important;
    color: ${grey[50]};
  }
`;

const HighlightedButtonContainer = styled(Flex)`
  color: ${brand.accent} !important;

  a {
    color: currentColor !important;
  }
`;

const Divider = styled.hr`
  background-color: ${grey[70]};
  border: none;
  height: 1px;
  width: 100%;

  &.nav-panel-item {
    opacity: 0;
  }
`;

const StyledFlex = styled(Flex)<{
  hasSecondaryLinks: boolean;
}>`
  ${({ hasSecondaryLinks }) =>
    !hasSecondaryLinks &&
    `
      padding: 0 0 ${spacing[32]} 0 !important;
  `}
`;

const StyledBadge = styled(Badge)`
  font-size: 13px;
  border-radius: ${spacing[4]}px;
  padding: ${spacing[4]}px ${spacing[8]}px;
  margin-left: ${spacing[12]};
  max-height: ${spacing[24]};
  white-space: nowrap;
`;
